.container-fluid.gma-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100vh;
	background-color: #080c10; /* Black background */
  }

  
  body
{
	
	background-color: #080c10; /* Black background */
}
  .logo-container {
	margin: 0 auto;
  }

  @import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

  



  .retro-button {
	background: rgba(34, 151, 184, 0.8);
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	margin: 4px 2px;
	cursor: pointer;
	padding: 10px 60px;
	border-radius: 5px;
	font-family: 'Luckiest Guy', cursive; /* Changed font */
	transition: all 0.3s ease;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); /* Added box shadow for a more modern look */
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; /* Added text outline */
  }



  .retro-button:hover {
	background: rgba(34, 151, 184, 0.9);
	transform: translateY(-2px); /* Added subtle lift on hover for a more modern look */
	box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2); /* Increase box shadow on hover */
  }
  
  .retro-button:active {
	transform: translateY(2px); /* Push the button down on click */
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); /* Reduce box shadow on click */
  }

  .logo-container {
	text-align: center;
	padding: 10px;
	margin-top: 40px;
  }
  
  .logo-wrapper {
	position: relative;
	display: flex; /* Added */
	justify-content: center; /* Added */
	align-items: center; /* Added */
  }
  

  
  .logo-large {
	max-width: 490px;
	max-height: 490px;
	height: auto;
  }
  
  .animated-logo {
	position: absolute;
	max-width: 490px;
	max-height: 490px;
	height: auto;
	animation: moveAndRotate 4s ease-in-out infinite, shadow 4s ease-in-out infinite;
	filter: drop-shadow(0 0 10px rgba(0, 0, 0, 1));
  }

  @keyframes moveAndRotate {
	0% { transform: translate(0, -2px) rotate(-0deg); }
	50% { transform: translate(0, 1px) rotate(0deg); }
	100% { transform: translate(0, -2px) rotate(-0deg); }
  }

  @keyframes shadow {
	0% { filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.8)); }
	50% { filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.8)); }
	100% { filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.8)); }
  }

  .gma-wrapper .button-wrapper {
	position: relative;
	top: -50px; /* Adjust as needed */
  }


  @media (max-width: 576px) { /* Bootstrap's sm breakpoint */
	.logo-container {
	  margin-top: -130px; /* Adjust as needed */
	}
  
	.logo-large {
	  max-width: 400px;
	  max-height: 400px;
	  height: auto;
	}
  
	.animated-logo {
	  position: absolute;
	  max-width: 400px;
	  max-height: 400px;
	  height: auto;
	  animation: moveAndRotate 4s ease-in-out infinite, shadow 4s ease-in-out infinite;
	  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 1));
	}
  
	.gma-wrapper .button-wrapper {
		position: relative;
		top: -110px; /* Adjust as needed */
	  }
  }